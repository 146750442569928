<template>
  <div class="about">

  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

<style lang="scss">
.about {
    background-color: green;
    display: flex;
    min-height: calc(100vh);

}

// @media (max-width:640px) {
//     .navbar {
//         display: flex;
//         justify-content: space-between;
//         align-items: left;
//         padding: 30px 10%;
//     }
// }

</style>
